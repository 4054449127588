<template>
    <div class="stage-inner-container-padded">
        <div v-if="!fetchingDevice && !hasError">
            <b-container>
                <b-row cols="8" align-h="center">
                    <b-col align-self="center">
                        <div class="d-flex justify-content-center">
                            <h2>{{editModal.type}} {{name_singular}}</h2>
                        </div>
                    </b-col>
                    <b-col lg="4" align-self="center">
                        <div class="d-flex justify-content-center">
                            <b-button 
                                v-if="$store.getters.checkUIPC({path: ['viewTest', 'actions', 'emailReport'] })"
                                variant="info" 
                                :disabled="(editModal.actee.attachment_file_id != null) ? false : true" 
                                @click="emailPDF()"
                            >
                                {{((editModal.actee.attachment_file_id != null) ? 'Email Test Report' : 'Cannot Email Summary PDF Reports')}}
                            </b-button>
                        </div>
                    </b-col>
                    <b-col lg="4" align-self="center">
                        <div class="d-flex justify-content-center">
                            <b-button
                                v-if="$store.getters.checkUIPC({path: ['eventList', 'actions', 'downloadPDF'] })"
                                :variant="(editModal.actee.attachment_file_id != null) ? 'warning' : 'info'"
                                @click="downloadPDF()"
                            >
                                {{((editModal.actee.attachment_file_id != null) ? 'Download Official PDF Report' : 'Download Summary PDF')}}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
                <b-row align-h="center">
                    <b-col align-self="center">
                         <b-form-group
                            :id=" 'fieldset-horizontal-' + name_singular.toLowerCase() + '-id' "
                            label-cols-sm="4"
                            label-cols-lg="3"
                            description=""
                            :label="transformToProperCase(pkid)"
                            :label-for="'input-horizontal-' + name_singular.toLowerCase() + '-id'"
                        >
                            <b-form-input disabled 
                                :id="'input-horizontal-' + name_singular.toLowerCase() + '-id'" 
                                v-model="editModal.actee[pkid]">
                            </b-form-input>
                        </b-form-group>
                        <!-- Describe Device -->
                        <b-row align-h="center">
                            <b-col align-self="center">
                                <h3>Device Information</h3>
                            </b-col>
                        </b-row>
                        <b-row no-gutters>
                            <b-col cols="12" sm="12" md="6" lg="3" xl="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>Model</b>
                                    </b-col>
                                    <b-col>
                                        {{ editModal.actee.manufacturer + ' ' + editModal.actee.type + ' ' + editModal.actee.model }}
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="12" sm="12" md="6" lg="3" xl="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>Size:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.device_size}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="12" sm="12" md="6" lg="3" xl="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>Serial:</b>
                                    </b-col>
                                    <b-col>
                                        {{editModal.actee.device_serial}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="12" sm="12" md="6" lg="3" xl="3">
                                 <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>GPS Location:</b>
                                    </b-col>
                                    <b-col >
                                        {{ (editModal.actee.device_latitude == null) ? 'No GPS Data' : editModal.actee.device_latitude + ', ' + editModal.actee.device_longitude}}
                                    </b-col>
                                </b-row>
                            </b-col>
                            
                        </b-row>
                        <hr>
                        <b-row>
                            <b-col align-self="center">
                                <h4>Initial Test Result: {{editModal.actee.initial_test_result}}</h4>
                            </b-col>
                        </b-row>
                        <b-row no-gutters>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('ck1_psi')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.ck1_psi}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('ck1_leak')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.ck1_leak}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('ck2_psi')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.ck2_psi}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('ck2_leak')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.ck2_leak}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('rv_open_at')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.rv_open_at}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('rv_leak')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.rv_leak}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('rv not open')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.rv_did_not_open}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                     <!-- Blank Slot -->
                                    <b-col cols="5"> &nbsp; </b-col><b-col></b-col>
                                </b-row>
                            </b-col>

                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('pvb_ag')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.ini_pvb_ag}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('pvb_ag_psid')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.init_pvb_air_open}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('ini_pvb_cv')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.ini_pvb_cv}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('pvb_cv_leak')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.pvb_cv_leak}} 
                                    </b-col>
                                </b-row>
                            </b-col>

                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('init_so1_close')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.init_so1_close}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('init_so1_leak')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.init_so1_leak}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('init_so2_close')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.init_so2_close}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('init_so2_leak')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.init_so2_leak}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row>
                            <b-col align-self="center">
                                <h4>Final Test Result: {{editModal.actee.final_test_result}}</h4>
                            </b-col>
                        </b-row>
                        <b-row no-gutters>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('ck1_psi')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.final_rpdc_ck1_psid}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('ck1_close')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.final_rpdc_ck1_close}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('ck2_psi')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.final_rpdc_cv2_psid}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('ck2_close')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.final_rpdc_cv2_close}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('rv_open_at')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.final_rp_rv_psid}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                     <!-- Blank Slot -->
                                    <b-col cols="5"> &nbsp; </b-col><b-col></b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                     <!-- Blank Slot -->
                                    <b-col cols="5"> &nbsp; </b-col><b-col></b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                     <!-- Blank Slot -->
                                    <b-col cols="5"> &nbsp; </b-col><b-col></b-col>
                                </b-row>
                            </b-col>

                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('pvb_ag')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.ini_pvb_ag}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('pvb_ag_psid')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.fin_pvb_ag_psid}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('pvb_cv')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.fin_pvb_cv_psid}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('pvb_cv_leak')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.fin_pvb_cv_leak}} 
                                    </b-col>
                                </b-row>
                            </b-col>

                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('so1_close')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.fin_so1_close}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('so2_close')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.fin_so2_close}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                     <!-- Blank Slot -->
                                    <b-col cols="5"> &nbsp; </b-col><b-col></b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="3">
                                <b-row no-gutters>
                                     <!-- Blank Slot -->
                                    <b-col cols="5"> &nbsp; </b-col><b-col></b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row no-gutters>
                            <b-col sm="12" md="6" lg="6">
                                <b-row no-gutters>
                                    <b-col cols="5">
                                        <b>{{transformToProperCase('curbstop_condition')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.curbstop_condition}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="6" lg="6">
                                <b-row no-gutters>
                                    <b-col cols="8">
                                        <b>{{transformToProperCase('verified_downstream_pressure')}}:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.verified_downstream_pressure}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row no-gutters>
                            <b-col sm="12" md="12" lg="12" v-if="false">
                                <b-row no-gutters>
                                    <b-col cols="4">
                                        <b>SO & TC Issues:</b>
                                    </b-col>
                                    <b-col >
                                        {{editModal.actee.so_tc_issues}} 
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="12" lg="12">
                                <b-row no-gutters>
                                    <b-col sm="12" md="6" lg="3">
                                        <b>{{transformToProperCase('Test & Equipment Notes')}}:</b>
                                    </b-col>
                                    <b-col sm="12" md="6" lg="9">
                                        <span v-if="editModal.actee.so_tc_issues != null">{{"SO TC Issues: " + editModal.actee.so_tc_issues.join(', ') + " "}}</span> 
                                        <span v-if="editModal.actee.quick_repair_notes != null">{{"Repair Notes: " + editModal.actee.quick_repair_notes.join(', ') + " "}}</span> 
                                        <span v-if="editModal.actee.equipment_or_test_comments_notes != null">{{editModal.actee.equipment_or_test_comments_notes.join(', ') + " "}}</span> 
                                        <span v-if="editModal.actee.verified_downstream_pressure != null">{{(editModal.actee.verified_downstream_pressure) ? "Verified Downstream Pressure ": ""}}</span>
                                        <span v-if="editModal.actee.condition != null">{{editModal.actee.condition.join(', ') + " "}}</span> 
                                        <span v-if="editModal.actee.condition_notes != null">{{editModal.actee.condition_notes + " "}}</span>  
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="4" lg="4"  v-if="editModal.actee.photo_of_bfpa != null">
                                <b-row no-gutters>
                                    <b-col cols="12">
                                        <b>BFPA Photo:</b>
                                    </b-col>
                                </b-row>
                                <b-row no-gutters>
                                    <b-col cols="12">
                                        <img-with-detail-modal :imgID="editModal.actee.photo_of_bfpa" viewSize="thumb"></img-with-detail-modal>
                                        <!-- <b-img :src="editModal.bfpa_photo.thumb.link" fluid alt="BPFA Photo" @click="enlargePhoto(editModal.bfpa_photo)" ></b-img> -->
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="4" lg="4"  v-if="editModal.condition_photo != null">
                                <b-row no-gutters>
                                    <b-col cols="12">
                                        <b>Condition Photo:</b>
                                    </b-col>
                                </b-row>
                                <b-row no-gutters>
                                    <b-col cols="12">
                                        <img-with-detail-modal :imgID="editModal.actee.condition_photo" viewSize="thumb"></img-with-detail-modal>
                                        <!-- <b-img :src="editModal.bfpa_photo.thumb.link" fluid alt="BPFA Photo" @click="enlargePhoto(editModal.bfpa_photo)" ></b-img> -->
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="4" lg="4" v-if="editModal.tech_signature != null">
                                <b-row no-gutters>
                                    <b-col cols="12">
                                        <b>Technician Signature:</b>
                                    </b-col>
                                </b-row>
                                <b-row no-gutters>
                                    <b-col cols="12">
                                        <img-with-detail-modal :imgID="editModal.actee.technician_signature" viewSize="small"></img-with-detail-modal>
                                        <!-- <b-img :src="editModal.tech_signature.small.link" fluid alt="Technician Signature" ></b-img> -->
                                    </b-col>
                                </b-row>
                                <b-row no-gutters>
                                    <b-col cols="12">
                                        {{editModal.actee.technician }} <br>
                                        {{'Gauge Serial: ' + editModal.actee.assigned_test_kit_sn}} <br>
                                        {{editModal.actee.technician_signature_timestamp}}
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="12" md="4" lg="4" v-if="editModal.fl_signature != null">
                                <b-row no-gutters>
                                    <b-col cols="12">
                                        <b>Fire Contractor Signature:</b>
                                    </b-col>
                                </b-row>
                                <b-row no-gutters>
                                    <b-col cols="12">
                                        <img-with-detail-modal :imgID="editModal.actee.fire_contractor_authorized_signature" viewSize="small"></img-with-detail-modal>
                                        <!-- <b-img :src="editModal.fl_signature.small.link" fluid alt="Fire Contractor Signature" ></b-img> -->
                                    </b-col>
                                </b-row>
                                <b-row no-gutters>
                                    <b-col cols="12">
                                        {{editModal.actee.fire_contractor_authorized_signature_timestamp}}
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row align-h="center">
                    <b-col align-self="center">
                        <b-button block v-show="!editModal.processing" class="mt-3" variant="info" @click="goBackToSearch()">Done</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div v-else-if="fetchingDevice">
            <b-container>
                <b-row align-h="center">
                    <b-col align-self="center" >
                        <div class="d-flex justify-content-center">
                            <b-spinner style="width: 5rem; height: 5rem;" label="Loading..." type="grow"></b-spinner>
                        </div>
                    </b-col>
                </b-row>
                <b-row align-h="center">
                    <b-col align-self="center" >
                        <div class="d-flex justify-content-center" >
                            <h3>{{loading_message}}</h3>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div v-else>
            <b-container>
                <b-row align-h="center">
                    <b-col align-self="center" >
                        <div class="d-flex justify-content-center">
                            <b-img src="/warning.svg" fluid alt="Fluid image"></b-img>
                        </div>
                    </b-col>
                </b-row>
                <b-row align-h="center">
                    <b-col align-self="center" >
                        <div class="d-flex justify-content-center" >
                            <h3>{{hasErrorValue}}</h3>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <b-modal ref="gallery-modal" id="modal-xl" size="xl" :ok-only="true" title="View Photo Details">
            <b-row>
                <b-col md="12" lg="6">
                    <b-img-lazy v-bind:src="photoModal.source" fluid ></b-img-lazy>
                </b-col>
                <b-col md="12" lg="6">
                    <div v-if="photoModal.gps.latitude != ''">
                        <span>
                            <strong><em>Photo Location Information:</em></strong>
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Accuracy:</em>
                            {{photoModal.gps.dop}}
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Latitude:</em>
                            {{photoModal.gps.latitude}}
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Longitude:</em>
                            {{photoModal.gps.longitude}}
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Altitude:</em>
                            {{photoModal.gps.altitude}}
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Timestamp:</em>
                            {{photoModal.gps.timestamp}}
                        </span>
                        <br />
                        <b-button class="tab-in" variant="info" size="sm" :href="photoModal.gmapViewLink" target="_blank">View GPS Location On Google Maps</b-button>
                        <hr>
                    </div>
                <span>
                    <strong><em>Available Actions:</em></strong>
                </span>
                <br />
                <b-button 
                    size="sm" 
                    class="btn-small-separation tab-in" 
                    :href="photoModal.sizes.original.link" 
                    download 
                    target="_blank"
                >
                    View Original Size
                </b-button>
                <br />
                <b-button 
                    size="sm" 
                    class="btn-small-separation tab-in" 
                    v-if="photoModal.sizes.large != null"
                    :href="photoModal.sizes.large.link" 
                    download 
                    target="_blank"
                >
                    View Large Size
                </b-button>
                <br />
                <b-button 
                    size="sm" 
                    class="btn-small-separation tab-in" 
                    v-if="photoModal.sizes.small != null"
                    :href="photoModal.sizes.small.link" 
                    download 
                    target="_blank"
                >
                    View Small Size
                </b-button>
                <br />
                <b-button 
                    size="sm" 
                    class="btn-small-separation tab-in" 
                    v-if="photoModal.sizes.thumbnail != null"
                    :href="photoModal.sizes.thumbnail.link" 
                    download 
                    target="_blank"
                >
                    View Thumbnail Size
                </b-button>
                <br />
                <b-button 
                    size="sm" 
                    class="btn-small-separation tab-in" 
                    v-if="photoModal.hasExif"
                    @click="toggleShowExifData()"
                >
                    {{(photoModal.showExif) ? "Hide Detailed Photo Info (EXIF)" : "View Detailed Photo Info (EXIF)"}}
                </b-button>
                </b-col>
            </b-row>
            <b-row v-if="photoModal.showExif">
                <b-col cols="12">
                    <hr>
                    <b-card sub-title="Server Data:">
                        <span class="tab-in">
                            <em>Original File Name:</em> {{photoModal.uploadFileName}}
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Uploaded On:</em> {{photoModal.uploadDate}}
                        </span>
                        <br />
                        <span class="tab-in">
                            <em>Uploaded By User ID:</em> {{photoModal.uploadedBy}}
                        </span>
                    </b-card>
                    <hr>
                    <b-card sub-title="Exif Data">
                        <div class="tab-in" v-for="(itm, index) in photoModal.exifKeys" :key="index">
                            <span>
                                <em>{{itm}}: </em> {{photoModal.exif[itm]}}
                            </span>
                            <br />
                        </div>
                    </b-card>
                </b-col>
            </b-row>
            <template v-slot:modal-footer="{ ok }">
                <small class="text-muted" v-if="photoModal.gps.timestamp != ''">Photo Taken On: {{photoModal.gps.timestamp}}</small>
                <small class="text-muted" v-else-if="photoModal.uploadDate != null">Photo Uploaded To System On: {{photoModal.uploadDate}}</small>
                <b-button @click="ok()" variant="primary">Done</b-button>
            </template>
        </b-modal>
        <email-test-modal :eventID="eventID" :requestOpen="openEmailModalReqCount" :dontMarkSent="true"></email-test-modal>
    </div>
</template>

<script>
const axios = require('axios');
const instance = axios.create({ timeout: 10000, headers: {'Content-Type': 'application/json'}, withCredentials: true, crossdomain: true });
const jpgInstance = axios.create({ timeout: 10000, headers: {'Content-Type': 'image/jpeg'}, withCredentials: true, crossdomain: true });
const pngInstance = axios.create({ timeout: 10000, headers: {'Content-Type': 'image/png'}, withCredentials: true, crossdomain: true });
const uuidv4 = require('uuid/v4');
const cloneDeep = require('lodash.clonedeep');
var fileDownload = require('js-file-download');
const _ = require('underscore');

import imgComp from '@/components/subcomponents/Image.vue'
import EmailTestReport from '@/components/subcomponents/EmailTestReport.vue'

export default {
    name: 'bfpatest',
    props: ['eventID'],
    components: {
        'img-with-detail-modal': imgComp,
        'email-test-modal': EmailTestReport,
    },
    data(){
        return{
            hasError: false,
            hasErrorValue: '',
            fetchingDevice: true,
            loading_message: 'Instantiating Instantiation Engine',
            required_requests: 1,
            completed_requests: 0,
            showAddressSelect: false,
            api_path: '',
            parent_path: '',
            pkid: 'event_id',
            name_singular: 'BFPA Test',
            name_pluaral: 'BFPA Tests',
            visible_fields: ['' ],
            allPossibleFields: [],
            tableFields: [],
            values: [],
            hasNoValues: false,
            photoModal:{
                showExif: false,
                uploadDate: null,
                uploadFileName: null,
                uploadedBy: null,
                source: '',
                sourceIs: 'original',
                sizes: {
                    thumbnail: null,
                    small: null,
                    large: null,
                    original: {
                        MIMEType: null,
                        link: null,
                        size: null
                    }
                },
                gps:{
                    altitude: '',
                    dop: '',
                    latitude: '',
                    longitude: '',
                    timestamp: ''
                },
                hasExif: false,
                exifKeys: [],
                exif:{},
                gmapViewLink: null
            },
            editModal: {
                type: 'Edit',
                fields: [],
                actee: {},
                parentChoices: [],
                deviceModels: [],
                gradeTypes: [],
                protectionTypes: [],
                bfpa_photo: null,
                tech_signature: null,
                fl_signature: null,
                condition_photo: null,
                processing: false,
                adrsInput: '',
                adrsFilled: false,
                showAdvancedAdrs: false,
                parsedAddress: {}
            },
            hasAddress: false,
            addressName: null,
            confirmationModal: {
                title: '',
                type: '',
                callbackPass: {},
                callback: ()=>{}
            },
            openEmailModalReqCount: 0,

        }
    },
    methods: {
        emailPDF(){
            this.openEmailModalReqCount++;
        },
        enlargePhoto(img){
            this.photoModal.uploadDate = new Date(img.uploadInfo.uploadedOn).toString();
            this.photoModal.uploadFileName = img.uploadInfo.uploadFileName;
            this.photoModal.uploadedBy = img.uploadInfo.uploadedBy;
            if(_.has(img, 'exif')){
                this.photoModal.hasExif = true;
                this.photoModal.exif = img.exif;
                this.photoModal.exifKeys = _.keys(img.exif);
            }
            if(_.has(img, 'gps')){
                this.photoModal.gps = {
                    altitude: (_.has(img.gps, 'alt') ? img.gps.alt : ''),
                    dop: (_.has(img.gps, 'dop') ? img.gps.dop : ''),
                    latitude: (_.has(img.gps, 'lat') ? img.gps.lat : ''),
                    longitude: (_.has(img.gps, 'lng') ? img.gps.lng : ''),
                    timestamp: (_.has(img.gps, 'timestamp') ? new Date(img.gps.timestamp).toString() : ''),
                }
            }
            this.photoModal.sizes = {
                thumbnail: (_.has(img, 'thumb') ? img.thumb : null),
                small: (_.has(img, 'small') ? img.small : null),
                large: (_.has(img, 'large') ? img.large : null),
                original: (_.has(img, 'original') ? img.original : null),
            };

            if(this.photoModal.sizes.large != null){
                this.photoModal.source = this.photoModal.sizes.large.link;
                this.photoModal.sourceIs = 'large';
            }else{
                this.photoModal.source = this.photoModal.sizes.original.link;
                this.photoModal.sourceIs = 'original';
            }
            var map = "https://www.google.com/maps/search/?api=1&query=" +
                this.photoModal.gps.latitude + "," + this.photoModal.gps.longitude;
            this.photoModal.gmapViewLink = map;
            this.$refs["gallery-modal"].show();
        },
        toggleShowExifData(){
            this.photoModal.showExif = !this.photoModal.showExif;
        },
        checkSuitabilityForIssuance(){
            var modalActee = this.editModal.actee;
            var rawcheckme = this.values.filter( (v) => { return v.device_id == this.editModal.actee.device_id });
            if(rawcheckme.length < 1){
                console.log('Must Save The Record Before Issuing A Work Order');
                this.makeToast("Cannot Issue To Fulcrum", "Must Save The Record Before Issuing A Work Order");
            }else{
                var checkme = rawcheckme[0];
                if(checkme.device_latitude != null && checkme.device_longitude != null && checkme.assembly_id != null){
                    // Required Items For Issuance To Fulcrum Are Present
                    instance.post(process.env.VUE_APP_API_BASE_URL + '/webhooks/create/' + checkme.device_id, {})
                        .then(async (response) => {
                            console.log('Issue To Fulcrum Success');
                            this.success_toast("Successfully Issued", "Record Should Be Available");
                        })
                        .catch((error) => {
                            console.log('Issue To Fulcrum Error')
                            console.log(error);
                        });
                    
                }else{
                    // Check The Kind Of Failure We Have
                    if( checkme.device_latitude != modalActee.device_latitude || checkme.device_longitude != modalActee.device_longitude || checkme.assembly_id != modalActee.assembly_id){
                        console.log('Must Save The Record Before Issuing A Work Order');
                        this.makeToast("Cannot Issue To Fulcrum", "Missing Latitude, Longitude, and/or Assembly ID \nEdits Should Be Saved Before Attempting To Issue.");
                    }else{
                        console.log('Just Missing The Stuff');
                        this.makeToast("Cannot Issue To Fulcrum", "Missing Latitude, Longitude, and/or Assembly ID");
                    }
                }
            }
            
            
        },
        makeToast(title, content, append = false) {
            this.toastCount++
            this.$bvToast.toast(content, {
                title: `${title}`,
                autoHideDelay: 5000,
                appendToast: append,
                variant: 'danger',
                solid: true,
            })
        },
        success_toast(title, content, append = false) {
            this.toastCount++
            this.$bvToast.toast(content, {
                title: `${title}`,
                autoHideDelay: 5000,
                appendToast: append,
                variant: 'success',
                solid: true,
            })
        },
        removeArrayItem(main_key, index){
            console.log('Requested Removal Of:');
            console.log(this.editModal.actee[main_key][index])
            console.log(this.editModal.actee[main_key])
            this.editModal.actee[main_key].splice(index, 1);
            console.log(this.editModal.actee[main_key])
        },
        addArrayItem(main_key, input_key){
            //console.log(this.editModal[input_key]);
            var x = cloneDeep(this.editModal[input_key]);
            if(!Array.isArray(this.editModal.actee[main_key])){
                this.editModal.actee[main_key] = [];
            }
            this.editModal.actee[main_key].push(x);
            this.editModal[input_key] = '';

        },
        fetchPageData () {
            //console.log(this)
            //Get User Token and Fetch The Values Required For This Page
            return new Promise( (resolve, reject) =>{
                instance.get(process.env.VUE_APP_API_BASE_URL + '/' + this.api_path + '/' + this.eventID + '.json')
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    if(error.response.status == 404){
                        // Unable To Find Event
                        this.hasError = true;
                        this.hasErrorValue = "Could Not Find Event With Specified ID In Database";
                    }
                    console.log(error);
                    reject(error);
                });
            })
            
        },
        fetchSecondaryChoices(){
            jpgInstance.get(process.env.VUE_APP_FILE_API_BASE_URL + '/meta/' + this.editModal.actee.photo_of_bfpa)
                .then((response) => {
                    this.editModal.bfpa_photo = response.data.result;
                    this.completed_requests = this.completed_requests + 1;
                })
                .catch((error) => {
                    console.log(error);
                    this.editModal.bfpa_photo = {};
                    this.completed_requests = this.completed_requests + 1;
                });
        },
        fetchTechSignature(){
            pngInstance.get(process.env.VUE_APP_FILE_API_BASE_URL + '/meta/' + this.editModal.actee.technician_signature)
                .then((response) => {
                    this.editModal.tech_signature = response.data.result;
                    this.completed_requests = this.completed_requests + 1;
                })
                .catch((error) => {
                    console.log(error);
                    this.editModal.tech_signature = {};
                    this.completed_requests = this.completed_requests + 1;
                });
        },
        fetchFireSignature(){
            pngInstance.get(process.env.VUE_APP_FILE_API_BASE_URL + '/meta/' + this.editModal.actee.fire_contractor_authorized_signature)
                .then((response) => {
                    this.editModal.fl_signature = response.data.result;
                    this.completed_requests = this.completed_requests + 1;
                })
                .catch((error) => {
                    console.log(error);
                    this.editModal.fl_signature = {};
                    this.completed_requests = this.completed_requests + 1;
                });
        },
        fetchConditionPhoto(){
            var condPhotoID = null;
            if(this.editModal.actee.condition_photo.length > 36){
                condPhotoID = this.editModal.actee.condition_photo.split(',')[0];
            }else{
                condPhotoID = this.editModal.actee.condition_photo;
            }
            pngInstance.get(process.env.VUE_APP_FILE_API_BASE_URL + '/meta/' + condPhotoID)
                .then((response) => {
                    this.editModal.condition_photo = response.data.result;
                    this.completed_requests = this.completed_requests + 1;
                })
                .catch((error) => {
                    console.log(error);
                    this.editModal.condition_photo = {};
                    this.completed_requests = this.completed_requests + 1;
                });
        },
        resetEditModal(){
            this.editModal.processing = false;
            this.editModal.fields = this.allPossibleFields;
            this.editModal.type = '';
            this.editModal.actee = {};
            //this.editModal.adrsInput = '';
        },
        openCreatorWindow(value){
            this.editModal.type = value;
            this.$bvModal.show('choice-editor');
        },
        editModalSave(){
            console.log(this.editModal);
            // Create Copy Of Actee
            var actCopy = cloneDeep(this.editModal.actee);
            // Remove The Calculated Fields
            delete actCopy.work_order_out; 
            this.commitChangeOrCreate( actCopy );
        },
        commitChangeOrCreate(input){   
        },
        transformToProperCase(val){
            var tmp = val.replace(/_/g, " ");
            return this.capitalizeEachWord(tmp);
        },
        capitalizeEachWord(str) {
            return str.replace(/\w\S*/g, function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        },
        goBackToSearch(){
            // Push Back To Home
            this.$router.go(-1);
        },
        downloadPDF(){
            const pdfInstance = axios.create({
                timeout: 10000,
                headers: {
                    'Content-Type': 'application/json'
                    },
                crossdomain: true,
                withCredentials: true,
                responseType: 'blob'
            });
            if(this.editModal.actee.attachment_file_id != null){
                //Get User Token and Fetch The Values Required For This Page
                pdfInstance.get(process.env.VUE_APP_FILE_API_BASE_URL + '/' + this.editModal.actee.attachment_file_id)
                .then(async (response) => {
                    fileDownload(response.data, this.editModal.actee.attachment_note);
                })
                .catch((error) => {
                    this.makeToast("Failed To Download Official Report PDF", "Unable to Download Official Report PDF", true);
                    if(error.response.status == 404){
                        console.log("File Not Found");
                    }
                    console.log(error);
                });
            }else{
                //Get User Token and Fetch The Values Required For This Page
                pdfInstance.get(process.env.VUE_APP_API_BASE_URL + '/download/pdf/test/' + this.eventID + '.pdf')
                .then(async (response) => {
                    console.log(response);
                    var contentDisp = response.headers["content-disposition"];
                    if(contentDisp != null){
                        var fileNameIndex = contentDisp.indexOf("filename=");
                        if(fileNameIndex == -1){
                            // Doesnt Have A FileName
                            fileDownload(response.data, 'Test Report ' + this.eventID + '.pdf');
                        }else{
                            // Has A FileName
                            var expectedFileName = contentDisp.match(/(?<=filename=").+(?=")/g)
                            console.log(expectedFileName);
                            if(expectedFileName.length > 0){
                                fileDownload(response.data, expectedFileName[0]);
                            }else{
                                fileDownload(response.data, 'Test Report ' + this.eventID + '.pdf');
                            }
                        }
                    }else{
                        // No File Name Specified
                        fileDownload(response.data, 'Test Report ' + this.eventID + '.pdf');
                    }
                    
                })
                .catch((error) => {
                    if(error.response.status == 404){
                        // Unable To Find Event
                        this.hasError = true;
                        this.hasErrorValue = "Unable To Generate PDF Report";
                    }
                    console.log(error);
                });
            }
            
        }
        
    },
    watch: {
        completed_requests: function (newVal, oldVal){
            var messages = [
                'Instantiating Instantiation Engine', 
                'Aligning Covariance Matrices', 
                'Normalizing Data Metrics', 
                'Resolving Quantum Irregularites', 
                'Pushing Pixels Around', 
                'Complete'
            ];
            this.loading_message = messages[newVal];
            console.log("Completed: " + newVal + "/" + this.required_requests);
            if(newVal == this.required_requests){
                this.fetchingDevice = false;
            }
        }
    },
    mounted (){
        console.log(this.eventID);
        this.api_path = 'events/test';
        this.completed_requests = 0;
        // Get Page Data And Reset Modal
        this.resetEditModal();
        this.fetchPageData()
            .then((res) =>{
                console.log('Promise Resolved');
                console.log(res);
                this.editModal.actee = res.data.result.records[0];
                if(res.data.result.records[0].photo_of_bfpa != null){
                    if(res.data.result.records[0].photo_of_bfpa != ""){
                        this.required_requests++;
                        this.fetchSecondaryChoices();
                    }
                }
                if(res.data.result.records[0].technician_signature != null){
                    if(res.data.result.records[0].technician_signature != ""){
                        this.required_requests++;
                        this.fetchTechSignature();
                    }
                }
                if(res.data.result.records[0].fire_contractor_authorized_signature != null){
                    if(res.data.result.records[0].fire_contractor_authorized_signature != ""){
                        this.required_requests++;
                        this.fetchFireSignature();
                    }
                }
                if(res.data.result.records[0].condition_photo != null){
                    if(res.data.result.records[0].condition_photo != ""){
                        this.required_requests++;
                        this.fetchConditionPhoto();
                    }
                }
                this.completed_requests++
            })
            .catch((error) =>{
                console.log('Promise Rejected');
                console.log(error);
                this.fetchingDevice = false;
            })
        //this.fetchSecondaryChoices();
    },
}
</script>

<style scoped>
.tab-in{
    margin-left: 1em;
}
.btn-small-separation{
    margin-bottom: 0.25em;
}
</style>
